import React, { useState } from 'react';
import Form from './Form';
import ThankYou from './ThankYou';
import { DATA_MODELS } from './Form/Field';

const FORM_ID = 'employer_preference';

const formConfig = [
  {
    label: 'First Preference',
    name: 'First_Preference_Name',
    isRequired: true,
    model: DATA_MODELS.deal
  },
  {
    label: 'Second Preference',
    name: 'Second_Preference_Name',
    isRequired: true,
    model: DATA_MODELS.deal
  },
  {
    label: 'Third Preference',
    name: 'Third_Preference_Name',
    isRequired: true,
    model: DATA_MODELS.deal
  },
];

const EmployerPreference = () => {
  const [submitted, setSubmited] = useState(false);
  const submit = () => {
    setSubmited(true);
  };

  if (submitted) {
    return <ThankYou />;
  }

  return (
    <Form
      headline="Employer Preference"
      formId={FORM_ID}
      config={formConfig}
      initValues={null}
      onSubmit={submit}
    />
  );
};

export default EmployerPreference;